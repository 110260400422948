module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FieldRoutes","short_name":"FieldRoutes","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"5d3849513db704bac55b169409e51970"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cumberland.fieldroutesthemes.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/pest-library","crumbLabel":"Pest Library"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","timeout":5000,"includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-cumberland/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"0ff68ea8-4523-0065-ecf4-80df8a04c8d9","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogIjY5NzYyMzhkYzRiZjQ4N2I4ZjlmMjA1ZDJkYjliNzhjIiwNCiAgImlhdCI6ICIxNjExMTc1MjM4IiwNCiAgImV4cCI6ICIxOTU2Nzc1MjM4IiwNCiAgInByb2plY3RfaWQiOiAiMGZmNjhlYTg0NTIzMDA2NWVjZjQ4MGRmOGEwNGM4ZDkiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.n4GGFKc4o9ID4MRgZpCWT9DmkCWvnGK5WgGVLgiB4fc","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://cumberland.fieldroutesthemes.com","title":"FieldRoutes","description":"Demo Cumberland","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"4799fbea7405c66b11ff1100ae16babbecf6a86dfdddb6fbb3ac5c2af4dfd246","recaptchaSiteKey":"6Ld5RUElAAAAACrR7dcuq0r8QibNbsiOGeKWA5h0","recaptchaSecretKey":"6Ld5RUElAAAAALGaqLSDw-xq1GEIT9HOKzvoWB-6","pestLibraryPathName":"pest-library","pestLibraryBreadcrumb":"Pest Library","pestLibraryActive":"0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
